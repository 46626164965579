/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { dateRangeService as dateService } from '@imaginelearning/web-ui-react';
import { DataFields, DateHelpers, UsageServiceIntervals, ManagerEntityTypes, pdfReportService } from '@imaginelearning/web-services';
import { GradeLevelToDigit } from '@imaginelearning/web-ui-reporting';
import { GrowthViews } from '@imaginelearning/web-reporting';

(function() {
	angular
		.module('dashboard.group', [
			'manager.modal.factory',
			'test.ranges',
			'ui.notifications',
			'ui.loader',
			'ui.router',
			'utils.date',
			'api.manager',
			'api.reporting',
			'il.web.features',
			'il.web.components',
			'manager.list',
			'api.assessment.provider',
			'api.application.user',
			'api.urls',
			'ui.directives.matchParentHeight',
			'ui.directives.matchDocumentHeight',
			'react.components',
			'react.dependencies'
		])
		.config(config)
		.controller('GroupDashboardController', controller);

	config.$inject = ['$stateProvider'];

	function config($stateProvider) {
		$stateProvider
			.state('dashboard.group', {
				url: '/group/:id',
				controller: controller,
				controllerAs: 'model',
				templateUrl: 'dashboards/group/group.html',
				data: {
					bodyClass: 'dashboard',
					contentClass: 'dashboard group'
				}
			})
			.state('dashboard.group.overview', {
				url: '/overview',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/overview.html'
					}
				}
			})
			.state('dashboard.group.portfolio', {
				url: '/portfolio',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/portfolio.html'
					}
				}
			})
			.state('dashboard.group.usage', {
				url: '/usage',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/usage.html'
					}
				}
			})
			.state('dashboard.group.usage.average', {
				url: '/average?start&end',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/usage.average.html'
					}
				}
			})
			.state('dashboard.group.usage.predicted', {
				url: '/predicted?start&end',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/usage.predicted.html'
					}
				}
			})

			.state('dashboard.group.progress', {
				url: '/progress',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/progress.html'
					}
				}
			})
			.state('dashboard.group.progress.achievements', {
				url: '/achievements?start&end',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/progress.achievements.html'
					}
				}
			})
			.state('dashboard.group.progress.gradeLevelMaterial', {
				url: '/gradeLevelMaterial?start&end',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/progress.grade.level.material.html'
					}
				}
			})
			.state('dashboard.group.progress.byLesson', {
				url: '/byLesson?start&end',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/progress.by.lesson.html'
					}
				}
			})
			.state('dashboard.group.progress.programLevelGains', {
				url: '/programLevelGains?start&end&curriculum',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/progress.program.level.gains.html'
					}
				}
			})
			.state('dashboard.group.progress.kready', {
				url: '/kready?start&end',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/progress.kready.html'
					}
				}
			})
			.state('dashboard.group.assessments', {
				url: '/assessments',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/assessments.html'
					}
				}
			})
			.state('dashboard.group.assessments.growth', {
				url: '/growth?start&end',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/assessments.growth.html'
					}
				}
			})
			.state('dashboard.group.assessments.overview', {
				url: '/overview?start&end',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/assessments.overview.html'
					}
				}
			})
			.state('dashboard.group.assessments.benchmark', {
				url: '/benchmark?start&end&grade',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/assessments.benchmark.html'
					}
				}
			})
			.state('dashboard.group.assessments.lexile', {
				url: '/lexile?start&end&grade&qualifier',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/assessments.lexile.html'
					}
				}
			})
			.state('dashboard.group.students', {
				url: '/students',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/students.html'
					}
				}
			})
			.state('dashboard.group.users', {
				url: '/users',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/users.html'
					}
				}
			})
			.state('dashboard.group.settings', {
				url: '/settings',
				views: {
					'': {
						templateUrl: 'dashboards/group/partials/settings.html'
					}
				}
			});
	}

	controller.$inject = [
		'$scope',
		'$q',
		'urls',
		'$state',
		'$window',
		'NotificationFactory',
		'Organization',
		'schoolYear',
		'Class',
		'CurrentDate',
		'DateRangeService',
		'GeneratePdfModal',
		'ReportingAPI',
		'ManagerAPI',
		'$rootScope',
		'ReportPrinting',
		'ManagerModalFactory',
		'TestRanges',
		'GrowthQualifierFilter',
		'GrowthGradeFilter',
		'HttpBusyService',
		'ApplicationUserService',
		'AuthenticationServiceEvents',
		'AssessmentProvider',
		'AssessmentProviders',
		'ReactDependenciesService',
		'$localStorage',
		'PendoService'
	];

	function controller(
		$scope,
		$q,
		urls,
		$state,
		$window,
		NotificationFactory,
		Organization,
		schoolYear,
		Class,
		CurrentDate,
		DateRangeService,
		GeneratePdfModal,
		ReportingAPI,
		ManagerAPI,
		$rootScope,
		ReportPrinting,
		ManagerModalFactory,
		TestRanges,
		GrowthQualifierFilter,
		GrowthGradeFilter,
		HttpBusyService,
		ApplicationUserService,
		AuthenticationServiceEvents,
		AssessmentProvider,
		AssessmentProviders,
		ReactDependenciesService,
		$localStorage,
		PendoService
	) {
		var model = this;

		model.reactDependenciesService = ReactDependenciesService;
		model.benchmarkTestGainsGroupLevelProps = undefined;
		model.groupUsageReportProps = undefined;
		model.usagePredictedProps = undefined;
		model.kindergartenReadinessProps = undefined;
		model.groupProgressByLessonProps = undefined;
		model.readingLevelAssessmentGroupReportWithProvider = undefined;
		model.groupDashboardProps = undefined;
		model.scaledScoreReportProps = undefined;
		model.managementTooltipProps = undefined;
		model.generatePDFModalProps = {
			buttonText: 'Print Roster',
			iconOnLeft: true,
			headerText: 'Generate Roster',
			btnClass: 'group-print-roster-button settings_link button float-right',
			onLoadData: printRosters,
			'data-testid': 'GroupPrintRosterButton'
		};

		model.UsageKpiProps = { busy: true, onOpenUsage };
		model.GrowthKpiProps = { busy: true, onOpenGrowth };
		model.DashboardGrowthKpiProps = { busy: true, onOpenGrowth };
		model.ProgressKpiProps = { busy: true, onOpenProgress };
		model.DashboardGroupTableProps = undefined;
		model.showGuidedReview = false;

		model.groupTableData = undefined;
		model.groupTablePagination = undefined;

		model.dateService = undefined;
		model.usageDate = undefined;

		model.initialized = undefined;
		model.busy = false;
		model.error = false;
		model.currentState = undefined;
		model.group = {};
		model.siteCode = undefined;

		model.defaultRange = undefined;
		model.dateRanges = {
			achievements: undefined,
			kready: undefined,
			usageAverage: undefined,
			usagePredicted: undefined,
			assessmentsGrowth: undefined,
			assessmentsBenchmark: undefined,
			assessmentsLexile: undefined,
			assessmentsOverview: undefined,
			gradeLevelMaterial: undefined,
			progressByLesson: undefined,
			programLevelGains: undefined,
			assessmentBenchmark: undefined,
			annualGrowth: undefined
		};

		model.gradeLevels = {
			programLevelGains: undefined,
			usageAverage: undefined,
			usagePredicted: undefined
		};

		model.grade = GrowthGradeFilter.getCurrentGrade();
		model.qualifier = GrowthQualifierFilter.getCurrentQualifier();
		model.lexileTest = TestRanges.currentTestRange(CurrentDate.get()) || TestRanges.beginning.value;
		model.programLevelGainsMode = undefined;

		model.stateChangeSuccess = stateChangeSuccess;
		model.edit = edit;
		model.printUsage = printUsage;
		model.importWizardUrl = '/import';
		model.modalClosed = modalClosed;
		model.showGalileoTestSettings = false;
		model.hideGrowthTab = false;
		model.isNwea = false;
		model.isThirdPartyAssessment = false;
		model.galileoUrl = $rootScope.appendAuthSourceQueryParam(urls.galileoUrl);
		model.defaultAssessmentProvider = AssessmentProviders.ILE;
		model.nweaUrl = urls.nweaUrl;
		model.onStudentListLoaded = onStudentListLoaded;
		model.showManagementTooltip = showManagementTooltip;
		model.closeManagementTooltip = closeManagementTooltip;
		model.showGuidedReview = false;
		model.imagineLearningUniversityUrl = urls.imagineLearningUniversityUrl;
		model.setupWizardUrl = urls.setupWizardUrl;

		model.combinedSubscription = undefined;

		model.httpBusy = function() {
			return HttpBusyService.isBusy();
		};

		model.userData = _.pick(ApplicationUserService.getUser(), ['id', 'userRole']);

		function closeManagementTooltip() {
			const quickEditElement = document.querySelector('[data-id="quick_edit"]');
			const importWizardElement = document.querySelector('[data-id="import_wizard"]');
			quickEditElement.classList.remove('hover-state');
			importWizardElement.classList.remove('hover-state');

			model.managementTooltipProps = undefined;
		}

		function showManagementTooltip() {
			const anchorElement = document.querySelector('[il-dropdown-menu="more-tabs"]');
			const quickEditElement = document.querySelector('[data-id="quick_edit"]');
			const importWizardElement = document.querySelector('[data-id="import_wizard"]');
			quickEditElement.classList.add('hover-state');
			importWizardElement.classList.add('hover-state');

			model.managementTooltipProps = {
				anchorObject: anchorElement,
				isOpen: true,
				onClose: model.closeManagementTooltip
			};
		}

		function updateDashboardWidgets() {
			ReportingAPI.group
				.get({
					id: model.group.id,
					startDate: model.usageDate,
					endDate: !model.last4Weeks.end || model.last4Weeks.end > CurrentDate.get() ? CurrentDate.get() : model.last4Weeks.end,
					dataFields: [DataFields.counts, UsageServiceIntervals.weeklyUsage, DataFields.gradeLevels]
				})
				.then(function(result) {
					model.last4weeksData = result;
					model.UsageKpiProps = {
						config: ReactDependenciesService.apiConfig(),
						data: model.last4weeksData,
						dateRange: { ...model.last4Weeks, start: model.usageDate },
						product: 'ILE',
						busy: !model.last4weeksData || !model.last4Weeks,
						onOpenUsage
					};
				})
				.catch(function(error) {
					NotificationFactory.error(error);
				});

			ReportingAPI.group
				.get({
					id: model.group.id,
					startDate: model.yearToDateRange.start,
					endDate:
						!model.yearToDateRange.end || model.yearToDateRange.end > CurrentDate.get()
							? CurrentDate.get()
							: model.yearToDateRange.end,
					dataFields: [
						DataFields.performance,
						DataFields.progress,
						DataFields.counts,
						DataFields.benchmarkTests,
						DataFields.lexileTestStatuses
					]
				})
				.then(function(results) {
					model.yearToDateData = results;
					model.GrowthKpiProps = {
						config: ReactDependenciesService.apiConfig(),
						assessmentProvider: model.defaultAssessmentProvider,
						onOpenGrowth: onOpenGrowth,
						organizationId: model.group.organizationId,
						data: model.yearToDateData,
						error: model.error,
						busy: !model.defaultAssessmentProvider || !model.yearToDateData,
						type: ManagerEntityTypes.Group
					};
					model.ProgressKpiProps = {
						config: ReactDependenciesService.apiConfig(),
						data: model.yearToDateData,
						type: ManagerEntityTypes.Group,
						busy: !model.yearToDateData,
						onOpenProgress
					};
				})
				.catch(function(error) {
					NotificationFactory.error(error);
				});
		}

		function printRosters() {
			return pdfReportService(ReactDependenciesService.apiConfig()).groupClassRoster(model.group.id);
		}

		function printUsage(summary, batch) {
			var gradeFetch = _.get(model, 'gradeLevels.usageAverage'),
				grade = gradeFetch && gradeFetch.toLowerCase() !== 'all' ? gradeFetch : undefined;
			GeneratePdfModal.generate(
				ReportPrinting.usage.group({
					id: model.group.id,
					summaryPage: summary,
					batchPages: batch,
					startDate: model.dateRanges.usageAverage.start,
					grade: grade,
					endDate: model.dateRanges.usageAverage.end
				}),
				'Generate Usage Report',
				undefined,
				model.group.name + ' Usage.pdf'
			);
		}

		function edit() {
			ManagerModalFactory.edit('group', model.group.id);
		}

		// grade watch
		$scope.$watch(function() {
			return GrowthGradeFilter.getCurrentGrade();
		}, _onGradeChange);

		function _onGradeChange(grade, grade_old) {
			if (grade !== grade_old) {
				var assessment, targetRoute, params;
				model.grade = grade;
				assessment = $state.current.name.match(/dashboard.group.assessments.benchmark/gi) ? 'benchmark' : 'lexile';
				targetRoute = 'dashboard.group.assessments.' + assessment;
				params = angular.copy($state.params);
				params.grade = model.grade === 'All' ? undefined : model.grade;
				$state.go(targetRoute, params, { notify: true });
			}
		}

		$scope.$on('$stateChangeSuccess', stateChangeSuccess);

		function modalClosed() {
			refreshModel(model.group.id, true);
		}

		function onOpenUsage() {
			$state.go('dashboard.group.usage.average', { id: model.group.id });
		}

		function onOpenGrowth(assessmentProvider) {
			if (assessmentProvider === 'NWEA') {
				$window.location.href = model.nweaUrl;
			} else if (assessmentProvider === 'ATI') {
				$window.location.href = model.galileoUrl;
			} else {
				$state.go('dashboard.group.assessments.benchmark', { id: model.group.id });
			}
		}

		function onOpenNewGrowth(assessmentProvider) {
			if (assessmentProvider === 'NWEA') {
				$window.location.href = model.nweaUrl;
			} else if (assessmentProvider === 'ATI') {
				$window.location.href = model.galileoUrl;
			} else {
				$state.go('dashboard.growth', model.growthNavParams);
			}
		}

		function onOpenProgress() {
			$state.go('dashboard.group.progress.byLesson', { id: model.group.id });
		}

		function onGroupRowClick(ids) {
			$state.go('dashboard.student', { id: ids.id, navGroupId: model.group.id });
		}

		function refreshModel(id, force = false) {
			if (!force && (model.busy || model.yearToDateRange !== undefined)) {
				return $q.when();
			}

			$localStorage.selectedStudents = [];
			model.error = false;
			model.busy = true;

			return Class.get(id, { additionalFields: ['parentName', 'studentCount'] })
				.then(function(result) {
					model.group = result || {};
					model.generatePDFModalProps = { ...model.generatePDFModalProps, fileName: model.group?.name + ' Roster.pdf' };
					return schoolYear.get(model.group.organizationId);
				})
				.then(function(result) {
					model.session = result;
					model.dateService = dateService(model.session);

					model.yearToDateRange = model.dateService.yearToDate(result);
					model.last4Weeks = model.dateService.last4Weeks(model.session);
					model.lastWeek = model.dateService.lastWeek(model.session);
					model.thisWeek = model.dateService.thisWeek(model.session);

					model.usageDate = new Date(model.last4Weeks.end.getTime());
					model.usageDate.setHours(0, 0, 0, 0);
					model.usageDate.setDate(model.usageDate.getDate() - 21);

					const dateHelpers = new DateHelpers();
					model.showSchoolYearWarningBanner = !dateHelpers.isWithinRange(
						CurrentDate.get(),
						model.session.start,
						model.session.end
					);
					const schoolYearWarningBannerDate = new Date(model.session.start);
					schoolYearWarningBannerDate.setFullYear(schoolYearWarningBannerDate.getFullYear() + 1);
					model.schoolYearWarningBannerProps = {
						startDate: schoolYearWarningBannerDate
					};

					model.growthNavParams = {
						districtId: model?.organization?.parentId,
						schoolId: model?.group?.organizationId,
						groupId: model?.group?.id
					};
				})
				['catch'](function(error) {
					if (error.status === 404) {
						//The group doesn't exist and we somehow ended up here (we just deleted it or this was the last page we visited)
						$state.go('dashboard.user', { id: ApplicationUserService.getUser().id }, { notify: true });
						return;
					}
					model.error = NotificationFactory.error(error);
				})
				['finally'](function() {
					model.busy = false;
					model.initialized = true;
					model.importWizardUrl = urls.setupWizardUrl + '?wizardReturnUrl=' + encodeURIComponent(window.location.pathname);
				});
		}
		function growthReportGroupTableRowClick(ids) {
			$state.go('dashboard.student.assessments.benchmark', { id: ids.id, navGroupId: model.group.id }, { notify: true });
		}
		function onRLAGroupTableRowClick(ids) {
			$state.go('dashboard.student.assessments.lexile', { id: ids.id, navGroupId: model.group.id }, { notify: true });
		}
		function growthReportGroupGradeChange(grade) {
			$state.go('dashboard.group.assessments.benchmark', { grade }, { notify: false });
		}
		function onRLAGroupGradeChange(grade) {
			$state.go('dashboard.group.assessments.lexile', { grade }, { notify: false });
		}

		function usageReportStudentTableRowClick(id, start, end) {
			$state.go('dashboard.student.usage', { id, start, end, navGroupId: model.group.id }, { notify: true });
		}

		function usageReportDateRangeChanged(dateRange) {
			model.dateRanges.usageAverage = dateRange;
			DateRangeService.updateRangeParams(model.dateRanges.usageAverage, $state);
		}

		function portfolioStudentNameClick(studentId) {
			$state.go('dashboard.student.portfolio', { id: studentId, navGroupId: model.group.id }, { notify: true });
		}

		function portfolioStudentNewClick(studentId) {
			$state.go('dashboard.student.portfolio', { id: studentId, navGroupId: model.group.id, filter: 'new' }, { notify: true });
		}

		function portfolioStudentRecordingClick(studentId) {
			$state.go('dashboard.student.portfolio', { id: studentId, filter: 'recordings', navGroupId: model.group.id }, { notify: true });
		}

		function portfolioStudentWritingClick(studentId) {
			$state.go('dashboard.student.portfolio', { id: studentId, filter: 'writings', navGroupId: model.group.id }, { notify: true });
		}

		function usagePredictedStudentTableRowClick(id, start, end) {
			$state.go('dashboard.student.usage', { id, start, end, navGroupId: model.group.id }, { notify: true });
		}

		function usagePredictedDateRangeChanged(dateRange) {
			model.dateRanges.usagePredicted = dateRange;
			DateRangeService.updateRangeParams(model.dateRanges.usagePredicted, $state);
		}

		function kinderReadyNameClicked(id) {
			$state.go('dashboard.student.progress.kready', { id: id, navGroupId: model.group.id }, { notify: true });
		}

		function progressByLessonNameClicked(id) {
			$state.go('dashboard.student.progress.byLesson', { id: id, navGroupId: model.group.id }, { notify: true });
		}

		function progressByLessonGroupClicked(link) {
			$window.open($rootScope.appendAuthSourceQueryParam(link), '_blank');
		}

		function progressByLessonActionAreasClicked() {
			$window.open($rootScope.appendAuthSourceQueryParam('/action-areas'), '_blank');
		}

		function progressStudentNameClicked(id) {
			const params = {
				id,
				navGroupId: model.group.id,
				start: !!model.dateRanges.assessmentsGrowth?.start
					? DateRangeService.getDateString(model.dateRanges.assessmentsGrowth.start)
					: undefined,
				end: !!model.dateRanges.assessmentsGrowth?.end
					? DateRangeService.getDateString(model.dateRanges.assessmentsGrowth.end)
					: undefined
			};

			$state.go('dashboard.student.assessments.growth', params, { notify: true });
		}

		function onBenchmarkSchedulerClicked() {
			$window.location.href = $rootScope.appendAuthSourceQueryParam(
				urls.galileoUrl + '?landingURL=' + encodeURIComponent('/GalileoASP/QuickTestScheduler')
			);
		}

		function scaledScoreDateRangeChange(dateRange) {
			model.dateRanges.assessmentsGrowth = dateRange;
			DateRangeService.updateRangeParams(model.dateRanges.assessmentsGrowth, $state);
		}

		function onPortfolioHeaderClicked() {
			$state.go('dashboard.group.portfolio');
		}

		function onSetupWizardClicked() {
			$window.location.href = $rootScope.appendAuthSourceQueryParam(model.setupWizardUrl);
		}

		function onPrintoutsClicked() {
			window.open('/resources/printouts', '_blank');
		}
		function onClassroomActivitiesClicked() {
			window.open('/resources/classroomActivities', '_blank');
		}
		function onReteachingClicked() {
			window.open('/resources/reteachingLessonPlans', '_blank');
		}

		function onAssignStudentsProducts(students) {
			$localStorage.selectedStudents = students;
			$localStorage.assignStudentsProductsReturnUrl = $window.location.href;
			$window.location.href = $rootScope.appendAuthSourceQueryParam(urls.setupWizardUrl + '/assign-product-access');
		}

		function onAddStudent() {
			$window.location.href = $rootScope.appendAuthSourceQueryParam('/import');
		}

		// PENDO LOCATION_API: remove when using routes
		function onManagementModalOpen(modalType) {
			PendoService.pendoService.updateLocationWithQueryParam(modalType ?? 'managementModal');
		}
		function onManagementModalClose() {
			PendoService.pendoService.revertToBrowserUrl();
		}

		function stateChangeSuccess(event, toState, toParams, fromState, fromParams) {
			model.currentState = toState.name;
			if (toState.name && toState.name === 'dashboard.group') {
				$state.go('dashboard.group.overview', { id: toParams && toParams.id ? toParams.id : undefined }, { notify: true });
			}

			if (toState.name && toState.name === 'dashboard.group.usage') {
				$state.go('dashboard.group.usage.average', { id: toParams && toParams.id ? toParams.id : undefined }, { notify: true });
			}

			if (toState.name && toState.name === 'dashboard.group.assessments') {
				$state.go(
					'dashboard.group.assessments.benchmark',
					{ id: toParams && toParams.id ? toParams.id : undefined },
					{ notify: true }
				);
			}

			if (toState.name && toState.name === 'dashboard.group.progress') {
				$state.go('dashboard.group.progress.byLesson', { id: toParams && toParams.id ? toParams.id : undefined }, { notify: true });
			}

			if (toState.name && toState.name === 'dashboard.group.progress.programLevelGains') {
				model.programLevelGainsMode =
					toParams && toParams.curriculum ? toParams.curriculum : model.programLevelGainsMode || undefined;
			}

			if (toState.name && toState.name === 'dashboard.group.assessments.lexile') {
				model.grade = toParams.grade ? toParams.grade : undefined;
				model.qualifier = toParams.qualifier ? toParams.qualifier : undefined;
			}

			if (toParams && toParams.id && toState.name && toState.name.match(/dashboard.group./gi)) {
				// stash away range in params to allow drill down continuity
				model.defaultRange =
					toParams.start !== undefined && toParams.end !== undefined
						? DateRangeService.getRange(toParams.start, toParams.end || CurrentDate.get())
						: undefined;
				refreshModel(toParams.id).then(function() {
					if (toState.name === 'dashboard.group.overview') {
						ManagerAPI.Group.students(model.group.id).then(function(result) {
							model.groupGradeLevel =
								result.totalCount === 1 ||
								(result.items?.length > 1 &&
									result.items?.every(student => student?.gradeLevel === result.items?.[0]?.gradeLevel))
									? result.items[0].gradeLevel
									: null;

							AssessmentProvider.getForOrgAndChildren(model.group.organizationId)
								.then(provider => {
									const gradesInGroup =
										result.items?.length > 0
											? _.uniq(result.items.map(student => GradeLevelToDigit[student.gradeLevel]))
											: [];

									if (
										gradesInGroup.length > 0 &&
										gradesInGroup.every(
											gradeLevel =>
												provider.gradeOverrides?.[gradeLevel] &&
												provider.gradeOverrides[gradeLevel] === provider.gradeOverrides[gradesInGroup[0]]
										)
									) {
										model.defaultAssessmentProvider = provider.gradeOverrides[gradesInGroup[0]];
									} else {
										model.defaultAssessmentProvider = provider.defaultAssessmentProvider;
									}

									model.hideGrowthTab =
										model.defaultAssessmentProvider === AssessmentProviders.ATI ||
										model.defaultAssessmentProvider === AssessmentProviders.NWEA;
									model.isThirdPartyAssessment = model.hideGrowthTab;
									model.showGalileoTestSettings = model.defaultAssessmentProvider === AssessmentProviders.ATI;
									// Update the sidepanel props for this
									model.sidePanelProps = {
										...model.sidePanelProps,
										showGalileoTestSettings: model.showGalileoTestSettings
									};
									model.isNwea = model.defaultAssessmentProvider === AssessmentProviders.NWEA;

									function setDashboardKpiProps() {
										model.DashboardGrowthKpiProps = {
											config: ReactDependenciesService.apiConfig(),
											growthApi: $rootScope.growthReportApi,
											assessmentProvider: AssessmentProviders[model.defaultAssessmentProvider],
											type: GrowthViews.Group,
											onOpenGrowth: onOpenNewGrowth,
											onOpenGrowthOV: onOpenNewGrowth,
											onOpenGrowthLexile: onOpenNewGrowth,
											id: model.group.id,
											busy: !model.defaultAssessmentProvider
										};
									}
									$rootScope.growthReportApi
										? setDashboardKpiProps()
										: $rootScope.$on(AuthenticationServiceEvents.growthReportRolloverLoaded, () => {
												setDashboardKpiProps();
										  });
								})
								.catch(error => NotificationFactory.error(error));
						});

						updateDashboardWidgets();

						model.DashboardGroupTableProps = {
							config: ReactDependenciesService.apiConfig(),
							group: model.group,
							groupId: model.group.id,
							session: model.session,
							onGroupRowClick: onGroupRowClick
						};

						model.sidePanelProps = {
							config: ReactDependenciesService.apiConfig(),
							organizationId: model.group.organizationId,
							userRole: model.userData.userRole,
							source: model.group,
							sourceType: 'group',
							userId: model.userData.id,
							showGalileoTestSettings: model.showGalileoTestSettings,
							onBenchmarkSchedulerClicked,
							onSetupWizardClicked,
							onPortfolioHeaderClicked,
							onPrintoutsClicked,
							onClassroomActivitiesClicked,
							onReteachingClicked,
							onAssignStudentsProducts,
							onAddStudent,
							onManagementModalOpen,
							onManagementModalClose,
							params: {
								startDate: model.session.start,
								endDate: model.session.end,
								product: 'ILE'
							}
						};
					}
					if (toState.name === 'dashboard.group.progress.achievements') {
						model.dateRanges.achievements = model.defaultRange || model.dateRanges.achievements || model.yearToDateRange;
						DateRangeService.updateRangeParams(model.dateRanges.achievements, $state);
					}
					if (toState.name === 'dashboard.group.usage.average') {
						model.dateRanges.usageAverage =
							model.defaultRange || model.dateRanges.usagePredicted || model.dateRanges.usageAverage || model.last4Weeks;
						DateRangeService.updateRangeParams(model.dateRanges.usageAverage, $state);

						model.groupUsageReportProps = {
							config: ReactDependenciesService.apiConfig(),
							entityDetails: model.group,
							session: model.session,
							onStudentTableRowClick: usageReportStudentTableRowClick,
							onDateRangeChanged: usageReportDateRangeChanged,
							params: {
								startDate: model.dateRanges.usageAverage.start,
								endDate: model.dateRanges.usageAverage.end,
								product: 'ILE'
							}
						};
					}
					if (toState.name === 'dashboard.group.usage.predicted') {
						model.dateRanges.usagePredicted =
							model.defaultRange || model.dateRanges.usageAverage || model.dateRanges.usagePredicted || model.last4Weeks;
						DateRangeService.updateRangeParams(model.dateRanges.usagePredicted, $state);

						model.usagePredictedProps = {
							config: ReactDependenciesService.apiConfig(),
							entityDetails: model.group,
							session: model.session,
							onStudentTableRowClick: usagePredictedStudentTableRowClick,
							onDateRangeChanged: usagePredictedDateRangeChanged,
							params: {
								startDate: model.dateRanges.usagePredicted.start,
								endDate: model.dateRanges.usagePredicted.end,
								product: 'ILE'
							}
						};
					}
					if (toState.name === 'dashboard.group.assessments.growth') {
						const yearToDateText = [
							DateRangeService.getDisplayDateString(model.yearToDateRange.start),
							DateRangeService.getDisplayDateString(model.yearToDateRange.end)
						].join(' - ');
						if (
							model.dateRanges.assessmentsGrowth == undefined ||
							model.defaultRange == undefined ||
							(model.defaultRange !== undefined && model.defaultRange.text === yearToDateText)
						) {
							model.dateRanges.assessmentsGrowth = model.yearToDateRange;
						}
						if (model.defaultRange !== undefined && model.defaultRange.text !== yearToDateText) {
							model.dateRanges.assessmentsGrowth = model.defaultRange;
						}
						DateRangeService.updateRangeParams(model.dateRanges.assessmentsGrowth, $state);
						model.scaledScoreReportProps = {
							config: ReactDependenciesService.apiConfig(),
							session: model.session,
							dateRange: model.dateRanges.assessmentsGrowth,
							sourceId: model.group.id,
							onDateRangeChanged: scaledScoreDateRangeChange,
							onClick: progressStudentNameClicked
						};
					}

					if (toState.name === 'dashboard.group.assessments.lexile') {
						model.grade = toParams.grade ? toParams.grade : undefined;
						model.dateRanges.assessmentsLexile =
							model.defaultRange || model.dateRanges.assessmentsLexile || model.yearToDateRange;
						DateRangeService.updateRangeParams(model.dateRanges.assessmentsLexile, $state);

						model.readingLevelAssessmentGroupReportWithProvider = {
							config: ReactDependenciesService.apiConfig(),
							group: model.group,
							session: model.session,
							onGroupTableRowClick: onRLAGroupTableRowClick,
							onGradeChange: onRLAGroupGradeChange,
							params: {
								startDate: model.dateRanges.assessmentsLexile?.start,
								endDate: model.dateRanges.assessmentsLexile?.end,
								product: 'ILE',
								grade: model.grade
							}
						};
					}

					if (toState.name === 'dashboard.group.assessments.benchmark') {
						model.grade = toParams.grade ? toParams.grade : undefined;
						model.dateRanges.assessmentsBenchmark = model.dateRanges.assessmentsBenchmark || model.yearToDateRange;
						DateRangeService.updateRangeParams(model.dateRanges.assessmentsBenchmark, $state);

						model.benchmarkTestGainsGroupLevelProps = {
							config: ReactDependenciesService.apiConfig(),
							group: model.group,
							session: model.session,
							params: {
								product: 'ILE',
								grade: model.grade
							},
							onGroupTableRowClick: growthReportGroupTableRowClick,
							onGradeChange: growthReportGroupGradeChange
						};
					}
					if (toState.name === 'dashboard.group.assessments.overview') {
						model.dateRanges.assessmentsOverview =
							model.defaultRange || model.dateRanges.assessmentsOverview || model.yearToDateRange;
						DateRangeService.updateRangeParams(model.dateRanges.assessmentsOverview, $state);
					}
					if (toState.name === 'dashboard.group.progress.gradeLevelMaterial') {
						model.dateRanges.gradeLevelMaterial =
							model.defaultRange || model.dateRanges.gradeLevelMaterial || model.yearToDateRange;
						DateRangeService.updateRangeParams(model.dateRanges.gradeLevelMaterial, $state);
					}
					if (toState.name === 'dashboard.group.progress.byLesson') {
						model.dateRanges.progressByLesson =
							model.defaultRange || model.dateRanges.progressByLesson || model.yearToDateRange;
						DateRangeService.updateRangeParams(model.dateRanges.progressByLesson, $state);

						model.groupProgressByLessonProps = {
							config: ReactDependenciesService.apiConfig(),
							groupId: model.group.id,
							onStudentNameClick: progressByLessonNameClicked,
							onLessonGroupClick: progressByLessonGroupClicked,
							onActionAreaToolClick: progressByLessonActionAreasClicked,
							simpleViewAvailable: true
						};
					}
					if (toState.name === 'dashboard.group.progress.programLevelGains') {
						model.dateRanges.programLevelGains =
							model.defaultRange || model.dateRanges.programLevelGains || model.yearToDateRange;
						DateRangeService.updateRangeParams(model.dateRanges.programLevelGains, $state);
					}
					if (toState.name === 'dashboard.group.progress.kready') {
						model.dateRanges.kready = model.yearToDateRange;
						DateRangeService.updateRangeParams(model.dateRanges.kready, $state);

						model.kindergartenReadinessProps = {
							config: ReactDependenciesService.apiConfig(),
							id: model.group.id,
							type: 'students',
							onClick: kinderReadyNameClicked
						};
					}

					if (toState.name === 'dashboard.group.assessments.annualGrowth') {
						model.dateRanges.annualGrowth = model.defaultRange || model.dateRanges.annualGrowth || model.yearToDateRange;
						DateRangeService.updateRangeParams(model.dateRanges.annualGrowth, $state);
					}

					if (toState.name === 'dashboard.group.portfolio') {
						Organization.siteCode(model.group.organizationId).then(function(siteCode) {
							const sessionEnd = siteCode.contains('demo') ? new Date() : model.session.end;
							model.siteCode = siteCode;
							model.groupPortfolioProps = {
								config: ReactDependenciesService.apiConfig(),
								groupId: model.group.id,
								params: {
									additionalFields: 'portalArtifacts',
									limit: 30,
									offset: 0,
									sortby: 'lastName,firstName'
								},
								onNameClick: portfolioStudentNameClick,
								onNewArtifactClick: portfolioStudentNewClick,
								onRecordingClick: portfolioStudentRecordingClick,
								onWritingClick: portfolioStudentWritingClick,
								session: { start: model.session.start, end: sessionEnd }
							};
						});
					}
				});
			}
		}

		$scope.$watchCollection(
			function() {
				return model.dateRanges;
			},
			function(value, oldValue) {
				if (!angular.equals(value, oldValue)) {
					if ($state.current.name === 'dashboard.group.progress.achievements') {
						DateRangeService.updateRangeParams(value.achievements, $state);
					}
					if ($state.current.name === 'dashboard.group.usage.average') {
						DateRangeService.updateRangeParams(value.usageAverage, $state);
					}
					if ($state.current.name === 'dashboard.group.usage.predicted') {
						DateRangeService.updateRangeParams(value.usagePredicted, $state);
					}
					if ($state.current.name === 'dashboard.group.assessments.growth') {
						DateRangeService.updateRangeParams(value.assessmentsGrowth, $state);
					}
					if ($state.current.name === 'dashboard.group.assessments.benchmark') {
						DateRangeService.updateRangeParams(value.assessmentsBenchmark, $state);
					}
					if ($state.current.name === 'dashboard.group.progress.gradeLevelMaterial') {
						DateRangeService.updateRangeParams(value.gradeLevelMaterial, $state);
					}
					if ($state.current.name === 'dashboard.group.progress.programLevelGains') {
						DateRangeService.updateRangeParams(value.programLevelGains, $state);
					}
					if ($state.current.name === 'dashboard.group.progress.byLesson') {
						DateRangeService.updateRangeParams(value.progressByLesson, $state);
					}
					if ($state.current.name === 'dashboard.group.progress.kready') {
						DateRangeService.updateRangeParams(value.kready, $state);
					}
					if ($state.current.name === 'dashboard.student.assessments.growth') {
						DateRangeService.updateRangeParams(value.assessmentsGrowth, $state);
					}
				}
			},
			true
		);

		function onStudentListLoaded(showGuidedReview) {
			model.showGuidedReview = showGuidedReview;
		}
	}
})();
